<template>
  <div class="modal-ads">
    <div class="bg" @click="selectClick"></div>
    <div class="modal-banner" v-if="banner">
      <div @click="clickBanner(banner)" class="banner-image">
        <img :src="banner.image" alt="ads banner" class="is-desktop" />
        <img :src="banner.mobileImage" alt="ads banner" class="is-mobile" />
      </div>
      <button class="button primary" @click="selectClick">
        Close this Ads.
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useBannerStore } from '~/store/banner'
import useFetchCustom from '@/services/global-useFetch'

const bannerState = useBannerStore()
// set variables
const banner = ref<any>(null)

const selectClick = async () => {
  bannerState.PopUpModal(false)
}

// fetch banner
const fetchPopupBanner = async () => {
  try {
    const { data, error, statusCode } = await useFetchCustom('/get-popup-banner-web').post().json()
    if (statusCode.value === 200 || statusCode.value === 202 || data.value) {
      const res: any = data.value
      banner.value = res.banner
      bannerState.PopUpModal(true)
    }
  } catch (error) {
    console.error('Error fetching popup', error)
  }
}

const clickBanner = async (item: any) => {
  const curId = item._id
  const url = item.url

  if (url) {
    try {
      await useFetchCustom('/banner-click-web').post({
        bannerId: curId
      })
    } catch (e) {
      console.error('Error adding click')
    }
    window.open(url, '_blank');
    bannerState.PopUpModal(false)
    return
  }
}
await fetchPopupBanner()

</script>
<style lang="scss" scoped>
img {
  max-width: 90vw;
  max-height: 70vh;
  display: block;
  cursor: pointer;

  &.is-mobile {
    display: none !important;
  }
}

@media (max-width: 769px) {
  img {
    &.is-mobile {
      display: block !important;
    }

    &.is-desktop {
      display: none !important;
    }
  }
}

.modal-ads {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 888;

  .bg {
    background-color: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 888;
  }

  .modal-banner {
    z-index: 999;
    position: relative;
    animation: slidedown 0.3s ease-in-out;

    @keyframes slidedown {
      from {
        transform: translateY(-100px);
      }

      to {
        transform: translateY(0);
      }
    }

    .banner-image {
      display: block;
      position: relative;


    }

    @media (max-width: 769px) {
      .button {
        font-size: 0.8rem;
      }
    }

    .button {
      margin-top: 5px;
      font-size: 1rem;
    }
  }
}
</style>
